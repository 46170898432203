import * as React from "react"
import tw from "twin.macro"

import Content from "../../components/pwcomps/content"
import { TransitionState } from "../../helpers/DummyGatsbyPluginTransitionLink"
import { Spring, animated as a } from "@react-spring/web"
import BreadCrumb from "../../components/breadcrumb"
import Footer from "../../components/footer"
import Seo from "../../components/seo"

const PageGeneral = props => {
  const { pageContext } = props
  const lang = pageContext.lang;

  return (
    <>
      <Seo
        pageContext={pageContext}
      />
      <TransitionState>
        {({ transitionStatus, exit, entry }) => {
          const isMounting = ["entering", "entered"].includes(transitionStatus)
          const isExiting = transitionStatus === "exiting"
          return (
            <>
              <div css={tw`px-5 md:px-9 overflow-x-visible`}>
                <div
                  css={tw`max-w-screen-xl my-0 mx-auto px-0 font-obbody overflow-x-visible`}
                >
                  <article>
                    <div css={tw`relative`}>
                      <BreadCrumb
                        isMounting={isMounting}
                        pageContext={pageContext}
                      />
                      <Spring
                        to={{
                          transform: `translateY(${
                            isMounting ? "0rem" : isExiting ? "-2rem" : "10rem"
                          })`,
                          opacity: isMounting ? 1 : 0,
                        }}
                      >
                        {styles => (
                          <a.div style={styles}>
                            <Content
                              contents={pageContext.content}
                              lang={pageContext.lang}
                              isMounting={isMounting}
                              isExiting={isExiting}
                              pageRuta={pageContext.ruta}
                            />
                          </a.div>
                        )}
                      </Spring>
                    </div>
                  </article>
                </div>
              </div>
              <Footer isMounting={isMounting} lang={lang} langSettings={pageContext.langSettings} />
            </>
          )
        }}
      </TransitionState>
    </>
  )
}

export default PageGeneral
